import React from "react";
import MyPageTypes from "../../../../components/MyPage/MyPageTypes";
import MyLoadingComponent from "../../../../components/MyLoading/MyLoadingComponent";

const MPageTab = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_Tab")
);
const MPageBillDetail = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_BillDetail")
);
const MPageMobileList = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_List")
);
const MPageMobile_List_WithLoadMore = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_List_WithLoadMore")
);
const MPageButtonList = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_ButtonList")
);
const MPageInventoryDetail = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_InventoryDetail")
);
const MPageDashboard = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_Dashboard")
);
const MTableMobile = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_Table")
);
const MProfile = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_Profile")
);
const MPageOrder = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_Order")
);
const MPageSearchInventory = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_Search_Inventory")
);
const MPageInputStock = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_InputStockDetail")
);
const MPageOrderStockDetail = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_OrderStockDetail")
);
const MPagePrintStation = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_PrintStation")
);
const MPageListOrder = React.lazy(() =>
  import("../../../../components/MyPage/TypesMobile/Mobile_ListOrder")
);
const MPageMobile_List_WithLoadInfinity = React.lazy(() =>
  import(
    "../../../../components/MyPage/TypesMobile/Mobile_List_WithLoadInfinity"
  )
);
const AppMyPage = {
  init() {
    MyPageTypes.addTypes("mobile_listorder", (props) => {
      return <MPageListOrder {...props} />;
    });
    MyPageTypes.addTypes("mobile_tab", (props) => {
      return <MPageTab {...props} />;
    });
    MyPageTypes.addTypes("mobile_billdetail", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MPageBillDetail {...props} />
        </React.Suspense>
      );
    });
    MyPageTypes.addTypes("mobile_list", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MPageMobileList {...props} />;
        </React.Suspense>
      );
    });
    MyPageTypes.addTypes("mobile_list_withloadmore", (props) => {
      return <MPageMobile_List_WithLoadMore {...props} />;
    });
    MyPageTypes.addTypes("mobile_list_withinfinity", (props) => {
      return <MPageMobile_List_WithLoadInfinity {...props} />;
    });
    MyPageTypes.addTypes("mobile_buttonlist", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MPageButtonList {...props} />
        </React.Suspense>
      );
    });
    MyPageTypes.addTypes("mobile_inventorydetail", (props) => {
      return (
        <React.Suspense fallback={<MyLoadingComponent />}>
          <MPageInventoryDetail {...props} />
        </React.Suspense>
      );
    });
    MyPageTypes.addTypes("mobile_dashboard", (props) => {
      return <MPageDashboard {...props} />;
    });
    MyPageTypes.addTypes("mobile_table", (props) => {
      return <MTableMobile {...props} />;
    });
    MyPageTypes.addTypes("mobile_profile", (props) => {
      return <MProfile {...props} />;
    });
    MyPageTypes.addTypes("mobile_order", (props) => {
      return <MPageOrder {...props} />;
    });
    MyPageTypes.addTypes("mobile_search_inventory", (props) => {
      return <MPageSearchInventory {...props} />;
    });
    MyPageTypes.addTypes("mobile_inputstockdetail", (props) => {
      return <MPageInputStock {...props} />;
    });
    MyPageTypes.addTypes("mobile_orderstockdetail", (props) => {
      return <MPageOrderStockDetail {...props} />;
    });
    MyPageTypes.addTypes("mobile_printstation", (props) => {
      return <MPagePrintStation {...props} />;
    });
  },
};
AppMyPage.init();
export default AppMyPage;
